<template>
<div class="homepage">
    <div class="main">
        <div class="main__center center">
            <div class="main__wrap">
                <div class="stage" data-aos="zoom-out" data-aos-duration="600">LE PAGINE DELLA NOSTRA VITA.</div>
                <h1 class="main__title d1"><span data-aos="animation-scale-top" data-aos-duration="600">Goditi l'esperienza</span><span data-aos="animation-scale-top" data-aos-duration="600" data-aos-delay="600">di rivivere i tuoi </span><span data-aos="animation-scale-top" style="color: #b648f6" data-aos-duration="600" data-aos-delay="1200">ricordi.</span></h1>
                <div class="main__text">Scopri come portare alla luce ricordi di cui nemmeno hai memoria.</div>
                <div class="main__btns">
                    <vs-button @click="callToAction(null)" size="large">Inizia la tua storia</vs-button>
                </div>
            </div>
            <div class="main__bg" data-aos>
                <div class="main__preview"><img src="../assets/figures-1.1.png" alt=""></div>
                <div class="main__preview"><img src="../assets/g2.png" alt=""></div>
                <div class="main__preview"><img src="../assets/g3.png" alt=""></div>
                <div class="main__preview"><img src="../assets/g4.png" alt=""></div>
            </div>
        </div>
    </div>

    <product-slider />

    <!--<div class="history">
        <div class="history__center center">
            <div class="history__container">
                <div class="history__wrap">
                    <h2 class="history__title h2" data-aos="animation-scale-top" data-aos-duration="600">Arriveremo presto!</h2>
                    <div class="history__info">Non siamo ancora pronti, ma ci stiamo preparando per regalarti la migliore esperienza possibile!</div>
                </div>
            </div>
            <div class="history__bg">
                <div class="history__preview"><img src="../assets/undraw_under_construction_-46-pa 1.png" style="width: 50%;" alt=""></div>
            </div>
        </div>
    </div>-->
    <vs-row justify="center" align="center" style="gap: 50px;">
        <img src="../assets/figures-4.1.png" style="max-width: 100%;" alt="" />
        <div class="description__wrap">
            <div class="stage">PHOTOBOOK</div>
            <h2 class="description__title h2" data-aos="animation-scale-top" data-aos-duration="600">Le pagine della nostra vita.</h2>
            <div class="description__text">Lo scrigno che conterrà le tue foto preferite, da custodire o da condividere con chi vuoi.<br />Libro fotografico fresato a colla, dimensioni 20x20, tre formati disponibili:<ul>
                    <li>48 pagine a soli 39€</li>
                    <li>60 pagine a soli 44€</li>
                    <li>72 pagine a soli 49€</li>
                </ul>
            </div>

            <vs-button style="margin:0;margin-bottom: 20px;" @click="callToAction(1)" size="large">Configuralo!</vs-button>
        </div>
    </vs-row>
    <vs-row justify="center" align="center" style="gap: 50px; background-color: white; padding-top: 50px; " class="mobile-reverse-flex">

        <div class="description__wrap">
            <div class="stage">MAGAZINE</div>
            <h2 class="description__title h2" data-aos="animation-scale-top" data-aos-duration="600">Non creiamo foto, creiamo ricordi</h2>
            <div class="description__text">Una rivista dedicata a te che sfoglierai piacevolmente: i momenti più emozionanti della tua vita, le foto dei tuoi viaggi e le notizie più entusiasmanti non colmeranno mai queste pagine.<br />Rivista per i tuoi ricordi e le tue foto a punto metallico, dimensioni 22 x 32, tre formati disponibili:<br/>
                <ul>
                    <li>24 pagine a soli 24€</li>
                    <li>36 pagine a soli 29€</li>
                    <li>48 pagine a soli 34€</li>
                </ul>
            </div>
            <vs-button style="margin:0;margin-bottom: 20px;" @click="callToAction(2)" size="large">Configuralo!</vs-button>
        </div>
        <img src="../assets/figures-5.1.png" style="max-width: 100%;" alt="" />
    </vs-row>

    <vs-row justify="center" align="center" style="gap: 50px; padding-top: 30px;">
        <img src="../assets/figures-10.1.png" style="max-width: 100%;" alt="" />

        <div class="description__wrap">
            <div class="stage">LIBRO AUTOBIOGRAFICO</div>
            <h2 class="description__title h2" data-aos="animation-scale-top" data-aos-duration="600">Crea la tua storia</h2>
            <div class="description__text">Lo strumento perfetto per raccontare una storia… magari quella della tua vita.<br/>Libro fresato a colla, dimensioni 15 x 21, da 92 pagine, a soli 33€.</div>
            <vs-button style="margin:0;margin-bottom: 20px;" @click="callToAction(7)" size="large">Configuralo!</vs-button>
        </div>
    </vs-row>

    <div class="comment" style=" background-color: white; padding-top: 50px;">
        <div class="comment__center center">
            <h2 class="comment__title h2" data-aos="animation-scale-top" data-aos-duration="600">Dicono di noi</h2>
            <div class="comment__container">
                <carousel class="comment__slider js-slider-comment" :autoplay="true" :nav="false" :responsive="{0:{items:1,nav:false},600:{items:3,nav:false}}">
                    <div class="comment__item">
                        <div class="comment__body">
                            <div class="comment__text">“Ottima qualità, sono rimasta molto soddisfatta! Consegna veloce!”</div>
                            <div class="comment__rating">
                                <div class="comment__icon"><img src="../assets/star-fill.svg" alt=""></div>
                                <div class="comment__icon"><img src="../assets/star-fill.svg" alt=""></div>
                                <div class="comment__icon"><img src="../assets/star-fill.svg" alt=""></div>
                                <div class="comment__icon"><img src="../assets/star-fill.svg" alt=""></div>
                            </div>
                        </div>
                        <div class="comment__author">
                            <div class="comment__ava"><img src="../assets/author/viviana.png" alt=""></div>
                            <div class="comment__details">
                                <div class="comment__man">Viviana</div>
                                <div class="comment__time">15 giorni fa</div>
                            </div>
                        </div>
                    </div>
                    <div class="comment__item">
                        <div class="comment__body">
                            <div class="comment__text">“Ho usato questo sito per il regalo della mia fidanzata, ero preoccupato perché in ritardo con i regali di natale ma hanno saputo soddisfare ogni mia richiesta!”</div>
                            <div class="comment__rating">
                                <div class="comment__icon"><img src="../assets/star-fill.svg" alt=""></div>
                                <div class="comment__icon"><img src="../assets/star-fill.svg" alt=""></div>
                                <div class="comment__icon"><img src="../assets/star-fill.svg" alt=""></div>
                                <div class="comment__icon"><img src="../assets/star-fill.svg" alt=""></div>
                                <div class="comment__icon"><img src="../assets/star-fill.svg" alt=""></div>
                            </div>
                        </div>
                        <div class="comment__author">
                            <div class="comment__ava"><img src="../assets/author/cristian.png" alt=""></div>
                            <div class="comment__details">
                                <div class="comment__man">Cristian</div>
                                <div class="comment__time">2 giorni fa</div>
                            </div>
                        </div>
                    </div>
                    <div class="comment__item">
                        <div class="comment__body">
                            <div class="comment__text">“Trovo davvero utile che su RemindMe si possano depositare i propri ricordi.”</div>
                            <div class="comment__rating">
                                <div class="comment__icon"><img src="../assets/star-fill.svg" alt=""></div>
                                <div class="comment__icon"><img src="../assets/star-fill.svg" alt=""></div>
                                <div class="comment__icon"><img src="../assets/star-fill.svg" alt=""></div>
                            </div>
                        </div>
                        <div class="comment__author">
                            <div class="comment__ava"><img src="../assets/author/jacopo.png" alt=""></div>
                            <div class="comment__details">
                                <div class="comment__man">Jacopo</div>
                                <div class="comment__time">6 giorni fa</div>
                            </div>
                        </div>
                    </div>
                    <div class="comment__item">
                        <div class="comment__body">
                            <div class="comment__text">“Una bellissima proposta, grazie mille.”</div>
                            <div class="comment__rating">
                                <div class="comment__icon"><img src="../assets/star-fill.svg" alt=""></div>
                                <div class="comment__icon"><img src="../assets/star-fill.svg" alt=""></div>
                                <div class="comment__icon"><img src="../assets/star-fill.svg" alt=""></div>
                                <div class="comment__icon"><img src="../assets/star-fill.svg" alt=""></div>
                            </div>
                        </div>
                        <div class="comment__author">
                            <div class="comment__ava"><img src="../assets/author/alessia.png" alt=""></div>
                            <div class="comment__details">
                                <div class="comment__man">Alessia</div>
                                <div class="comment__time">3 giorni fa</div>
                            </div>
                        </div>
                    </div>
                </carousel>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import carousel from 'vue-owl-carousel'
import ProductSlider from '../components/ProductSlider.vue';
export default ({
    components: {
        carousel,
        ProductSlider
    },
    

    methods: {
        callToAction(productId = null) {
            this.$parent.ctaProductId = productId;
            if (this.$parent.user != null) { // already logged in
                if (productId != null) { // direct to builder
                    this.$router.push('/store/' + productId);
                    return;
                } // otherwise feed
                this.$router.push({
                    name: 'feed'
                });
            } else {
                this.$parent.showSignUp();
            }
        }
    }
})
</script>

<style scoped>
ul {
    list-style-type: disclosure-closed;
    margin-left: 19px;
    margin-top: 10px;
}

@media screen and (max-width: 600px) {
    .description__wrap {
        padding-bottom: 50px;
    }
}
</style>